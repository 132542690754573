$white: #fff;
$dark-black: #000;
$black: #333;
$blue: #4580d8;
$light-blue: #9eb2bd;
$baby-blue: #42a7c6;
$bg-blue: #ccf3ff;
$dark-grey: #54575a;
$grey: #6f717a;
$light-grey: #d9dce2;
$baby-grey: #f3f4f6;
$bg-grey: #f9f9f9;
$pink: #e896b1;
$purple: #c17ae0;
$orange: #e44b33;
$green: #04a74d;
$red: #dc3545;
$dark-red: #c44b41;
$yellow: #faad14;
$tc_primary_blue: #18618e;
$tc_primary_light_gray: #d9d9d9;
$tc_neutral_200: #f3f6f9;
$soft_silver: #e6e6e6;
$slate_grey: #737373;

@mixin forceFontFamily {
  // font-family: "Avenir Next", "museo-sans", sans-serif !important;
}
@mixin forceFontFamilyCalendar {
  font-family: "Avenir Next", "Montserrat", "museo-sans", sans-serif !important;
}

@mixin bootstrap4 {
  .bootstrap4 {
    @content;
  }
}

@mixin customScrollbar(
  $color: #c7c7c7,
  $bgColor: #eee,
  $size: 4px,
  $radius: 2px
) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: $bgColor;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px $bgColor;
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background: $color;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: $color;
  }
}

@mixin transparentBorder {
  border: 1px solid transparent !important;
}

@import url(/css/gfonts.css);
$cs-white: #ffffff;
$cs-teal-light: #79c4e2;
$cs-teal: #42a7c6;
$cs-teal-dark: #3d94ab;
$cs-teal-darker: #38798f;
$cs-teal-darkest: #326074;
$cs-blue: #067ccd;
$cs-blue-light: #238bd4;
$cs-grey-darkest: #363d49;
$cs-grey-darker: #444b58;
$cs-grey-dark: #3e4c51;
$cs-grey: #6c6f79;
$cs-grey-light: #d8dce3;
$cs-grey-lighter: #edf0f4;
$cs-grey-lightest: #f3f4f6;
$cs-grey-user: #b8bcc4;
$cs-grey-logout: #9fa1a4;
$cs-red: #ff1839;
$cs-pink: #e279b7;
$cs-brown: #3f3001;
$cs-yellow: #ffda6a;
$cs-gold: #eeebc0;
$cs-black: #000;
$color_2: #111;
$color_4: #7d9fbb;
$color_5: #333;
$color_6: #444;
$color_7: #515151;
$color_8: #fff;
$color_9: #ccdae5;
$color_10: #ccd8e1;
$color_11: #999;
$cs-font: "museo-sans", sans-serif;
$background_color_1: #fafafa;
$background_color_3: #003057;
$background_color_4: rgba(0, 0, 0, 0.3);
$background_color_5: #003b6a;
$background_color_6: #00325a;
$background_color_7: #eee;
$background_color_8: #ef3e35;
$cs-transparent: transparent;
$background_color_10: 0;
$border_color_1: #c5c5c5;
@import "/css/gfonts.css";
body {
  font-family: $cs-font !important;
  font-weight: 300;
}

@media print {
  a[href]:after {
    content: none !important;
  }
  .hidden-print,
  .hidden-print * {
    display: none !important;
  }
  .visible-print,
  .visible-print * {
  }
}

select {
  width: auto;
  border: 2px solid $cs-grey-light;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

select::-ms-expand {
  visibility: hidden;
}

.select-container select {
  display: inline-block;
  min-width: 75px;
  border: 1px solid $cs-grey-light;
  background: #fff url("../images/cs-assets/cs-dropdown.svg") no-repeat;
  background-color: $cs-grey-lightest;
  background-size: 10px;
  height: 35px;
  background-position: right 8px center;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: $cs-grey;
  padding-left: 10px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

label {
  font-size: 16px;
  color: $cs-grey-dark;
  padding-right: 10px;
}

.title_bar_wht {
  font-family: sans-serif;
  font-size: 13pt;
  font-weight: bold;
  color: #ffffff;
}

.small_blk-bold {
  font-family: verdana, sans-serif;
  font-size: 9pt;
  font-weight: bold;
  color: #000000;
}

.ex_small_blk-bold {
  font-family: verdana, sans-serif;
  font-size: 7pt;
  font-weight: bold;
  color: #000000;
}

.ex_small_orange-bold {
  font-family: verdana, sans-serif;
  font-size: 7pt;
  font-weight: bold;
  color: black;
  background-color: orange;
}

.ex_small_blue-bold {
  font-family: verdana, sans-serif;
  font-size: 7pt;
  font-weight: bold;
  color: black;
  background-color: #abb3fc;
}

.medium_blk-bold {
  font-family: verdana, sans-serif;
  font-size: 10pt;
  font-weight: bold;
  color: #000000;
}

.small_wht-bold {
  font-family: verdana, sans-serif;
  font-size: 9pt;
  font-weight: bold;
  color: #ffffff;
}

.small_blk {
  font-family: verdana, sans-serif;
  font-size: 9pt;
  color: #000000;
}

.ex_small_blk {
  font-family: verdana, sans-serif;
  font-size: 7pt;
  color: #000000;
}

.small_red-bold {
  font-family: $cs-font, sans-serif;
  font-size: 16px;
  color: $cs-blue-light;
}

.today {
  border: 3px solid $cs-blue-light !important;
  .day-of-month p {
    color: $cs-blue-light !important;
  }
}

.warning {
  font-family: verdana, sans-serif;
  font-size: 9pt;
  color: red;
}

.req-fild {
  font-family: sans-serif;
  font-size: 8pt;
  color: red;
}

.med_blk-bold {
  font-family: verdana, sans-serif;
  font-size: 11pt;
  font-weight: bold;
  color: #000000;
}

.bold-wht {
  font-family: sans-serif;
  font-size: 11pt;
  font-weight: bold;
  color: #ffffff;
  &:link,
  &:visited {
    color: #ffffff;
  }
  &:hover {
    color: blue;
  }
}

.title {
  font-family: verdana, sans-serif;
  font-size: 15pt;
  font-weight: bold;
  color: #000000;
}

.plain {
  font-family: sans-serif;
  font-size: 11pt;
  color: #000000;
}

.plain-bold {
  font-family: sans-serif;
  font-weigtht: bold;
  font-size: 11pt;
  color: #000000;
}

.time-bold {
  font-family: verdana, sans-serif;
  font-size: 10pt;
  font-weigtht: bold;
  color: #000000;
}

.time {
  font-family: verdana, sans-serif;
  font-size: 9pt;
  color: #000000;
}

.calendar-small {
  font-family: verdana, sans-serif;
  font-size: 7pt;
  color: #000000;
}

.calendar-small-orng {
  font-family: verdana, sans-serif;
  font-size: 7pt;
  color: orange;
}

.calendar-day {
  font-family: verdana, sans-serif;
  font-size: 7pt;
  color: #000000;
}

.calendar-day .split-shift {
  border-left: 4px solid black;
  padding-left: 4px;
}

.calendar-day-gray {
  font-family: verdana, sans-serif;
  font-size: 7pt;
  color: #ffffff;
}

.calendar-holiday {
  font-family: verdana, sans-serif;
  font-size: 7pt;
  font-style: italic;
  color: #000000;
}

.year-holiday {
  font-family: verdana, sans-serif;
  font-size: 9pt;
  font-weight: bold;
  color: red;
}

.prim-hol-top {
  font-size: 14pt !important;
  font-weight: bold;
}

.year-sec_holiday {
  font-family: verdana, sans-serif;
  font-size: 9pt;
  font-weight: bold;
  color: green;
}

.error {
  font-family: verdana, sans-serif;
  font-size: 9pt;
  font-weight: bold;
  color: red;
}

.tally_journal_indicator {
  display: block;
  height: 16px;
  width: 16px;
  padding: 0;
  margin: 0;
  border: 0 none;
  position: absolute;
  right: -5px;
  top: -5px;
}

.tally_with_indicator {
  border-collapse: collapse;
  td {
    padding: 5px;
    border: 1px solid #000;
  }
  .small_blk .inner {
    position: relative;
  }
}

div.centered {
  width: 550px;
  margin: 0 auto;
}

.error-message {
  width: 36em;
  background: #eee url("/themes/login/error.png") 12px 50% no-repeat;
  padding: 1.8em 1em 1.8em 70px;
  border: 1px solid $cs-grey-light;
  margin: 0.5em 0 1.5em;
  color: #444;
  text-align: left;
}

.notice-message {
  width: 36em;
  background: #eee url("/themes/login/notice.png") 12px 50% no-repeat;
  padding: 1.8em 1em 1.8em 70px;
  border: 1px solid $cs-grey-light;
  margin: 0.5em 0 1.5em;
  color: #444;
  text-align: left;
}

$border_red: transparent transparent $cs-red transparent;
$border_blue: transparent transparent $cs-blue transparent;
$border_grey: transparent transparent $cs-grey transparent;
.tab-row {
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 30px;
  li {
    margin: 0;
    padding: 0 10px;
    background: #ddd;
    display: inline-block;
    position: relative;
    width: 100px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: $cs-white;
    cursor: pointer;
    cursor: hand;
    &:before {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 5px 26px 5px;
      border-color: $border_grey;
      position: absolute;
      content: " ";
      left: -5px;
      top: 4px;
      z-index: -1;
    }
    &:after {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 5px 26px 5px;
      border-color: $border_grey;
      position: absolute;
      content: " ";
      right: -5px;
      margin-top: 4px;
      z-index: -1;
    }
  }
  li.engine-1 {
    background: red;
    &:before {
      z-index: 1;
      border-color: $border_red;
    }
    &:after {
      z-index: 2;
      border-color: $border_red;
    }
  }
  li.engine-1-selected {
    background: red;
    &:before {
      z-index: 10;
      border-color: $border_red;
    }
    &:after {
      z-index: 20;
      border-color: $border_red;
    }
  }
  li.engine-2 {
    background: $cs-blue;
    &:before {
      z-index: 1;
      border-color: $border_blue;
    }
    &:after {
      z-index: 2;
      border-color: $border_blue;
    }
  }
  li.engine-2-selected {
    background: $cs-blue;
    &:before {
      z-index: 5;
      border-color: $border_blue;
    }
    &:after {
      z-index: 2;
      border-color: $border_blue;
    }
  }
}

/*================================
                &UNIVERSAL CLASSES
================================*/

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.padding-top {
  padding-top: 20px;
}

.padding-top-small {
  padding-top: 5px;
}

.padding-bottom {
  padding-bottom: 20px;
}

.padding-bottom-small {
  padding-bottom: 10px;
}

.margin-left-small {
  margin-left: 5px;
}

.margin-right-small {
  margin-right: 5px;
}

.margin-left {
  margin-left: 10px;
}

.margin-right {
  margin-right: 10px;
}

.hover-fade:hover {
  opacity: 0.5;
}

.no-border {
  border: none;
}

.text-center {
  text-align: center;
}

.custom-radio {
  input[type="radio"]:checked {
    border: 5px solid $cs-teal;
    background-color: $cs-teal;
  }
  input:nth-child(3) {
    margin-left: 10px;
  }
}

.btn-grey {
  background-color: $cs-grey-lighter;
  border: 1px solid $cs-grey-light;
  color: $cs-grey;
  font-size: 13px;
  font-weight: lighter;
  height: 35px;
  padding-left: 30px;
  padding-right: 30px;
}

.btn-grey:hover {
  background-color: $cs-grey-light;
  color: $cs-grey-dark;
}

.full-width {
  width: 100%;
}

h1.headspan {
  text-align: left;
  span {
    font-weight: bold;
  }
}

.no-top {
  margin-top: 0;
  padding-top: 0;
}

.no-border {
  border: none;
}

.no-bottom {
  padding-bottom: 0;
  margin-bottom: 0;
}

.border-bottom-row {
  width: 100%;
  border-bottom: 1px solid $cs-grey-lighter;
  margin-bottom: 10px;
}

.td-padding {
  padding-top: 15px !important;
}

.input-title {
  margin-right: 0;
  padding-right: 0;
  p {
    vertical-align: middle;
    margin-bottom: 0;
    margin-top: 10px;
  }
}

.input-title-notes {
  margin-right: 0;
  padding-right: 0;
  p {
    vertical-align: middle;
    margin-bottom: 0;
    margin-top: 21.5px;
  }
}

.date-selector-container {
  div {
    margin: 0;
    margin-left: 5px;
    margin-bottom: 10px;
    padding: 0;
  }
  div:nth-child(1) {
    margin-left: 15px;
  }
}

.next-button-span {
  img {
    height: 10px;
    width: 10px;
    margin-right: -5px;
    margin-bottom: 2px;
  }
}

.notes-container {
  textarea {
    resize: none;
    width: 100%;
    border: 2px solid $cs-grey-light;
    background-color: $cs-white;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    display: inline-block;
    min-width: 120px;
    border: 1px solid $cs-grey-light;
    background-color: $cs-grey-lightest;
    background-size: 10px;
    height: 125px;
    background-position: right 8px center;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: $cs-grey;
    padding-left: 10px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    margin-top: 20px;
    margin-bottom: 25px;
  }
}

.hide-unused-check {
  padding-bottom: 10px !important;
  padding-top: 10px;
}

.hide-unused-title {
  width: 127px;
}

/*============================
        REPORTS PAGES
============================*/

.report-page {
  font-family: $cs-font;
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
    color: $cs-grey-darker;
    font-weight: lighter;
  }
  label {
    padding-top: 6px;
  }
  .select-container {
    padding-bottom: 25px;
    select {
      width: 100%;
    }
  }
  .date-selector-container {
    div {
      margin: 0;
      margin-left: 5px;
      margin-bottom: 10px;
      padding: 0;
    }
    div:nth-child(1) {
      margin-left: 15px;
    }
  }
  .custom-format-table {
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4) {
      width: 25px;
    }
  }
  .custom-format-form {
    ul {
      padding: 10px;
      li {
        border-radius: 7px;
        cursor: pointer;
        label {
          padding-left: 10px;
        }
        a {
          float: right;
          padding-right: 10px;
          padding-top: 5px;
        }
      }
    }
    .orientation-select-container {
      border-radius: 5px;
      padding-bottom: 0;
      width: 290px;
      select {
        width: 100%;
        margin-top: 2px;
        padding-bottom: 0;
        background-color: $cs-grey-lightest;
        border: 1px solid $cs-grey-light;
        cursor: pointer;
      }
    }
    .subtotal-input {
      height: 35px;
      border-radius: 7px;
      border: 1px solid $cs-grey-light;
      padding-left: 10px;
      color: $cs-grey;
    }
    .column-list {
      select {
        width: 30%;
        background-color: $cs-white;
      }
    }
  }
  .horizontal-line {
    width: 100%;
    height: 18px;
    background-color: $cs-grey-lightest;
    margin: 25px 0 45px 0;
  }
  .date-title {
    font-size: 18px;
    color: $cs-grey;
    font-weight: lighter;
    margin-bottom: 10px;
  }
  .report-table {
    th {
      background-color: rgba(243, 244, 247, 1);
      padding-bottom: 0;
      padding-top: 10px;
      border-right: none;
      border-left: none;
      color: $cs-grey-darkest;
      vertical-align: middle !important;
      text-align: left;
      height: 50px;
      font-size: 11px;
    }
    td {
      text-align: left;
      vertical-align: middle;
      font-size: 11px;
    }
  }
}

.report-table-container {
  .current-report-table {
    font-family: "Open Sans", sans-serif;
    width: 100%;
    margin-left: 0;
    padding-bottom: 0;
    background-color: $cs-white;
    .group-name {
      font-weight: bold;
    }
    .horizontal-line {
      width: 100%;
      height: 18px;
      background-color: $cs-grey-lightest;
      margin: 25px 0 45px 0;
    }
    .report-day-off-table {
      width: 100%;
      th {
        font-size: 11px;
        background-color: rgba(243, 244, 247, 1);
        padding-bottom: 0;
        padding-top: 10px;
        border-right: none;
        border-left: none;
        color: $cs-grey-darkest;
        vertical-align: middle !important;
        text-align: left;
        height: 50px;
      }
      th:nth-child(2) {
        min-width: 100px !important;
      }
      td {
        vertical-align: middle;
        text-align: left;
      }
    }
    .header-row {
      padding-left: 0 !important;
    }
  }
  .custom-report-table {
    table {
      font-size: 11px;
      width: 100%;
      th:first-child {
        width: 220px;
      }
      th {
        background-color: $cs-grey-lightest;
        padding-bottom: 0px;
        padding-top: 5px;
        border-right: none;
        border-left: none;
        color: $cs-grey;
        min-width: 60px !important;
        vertical-align: middle;
        text-align: left;
        height: 50px;
      }
      td {
        min-width: 40px;
        vertical-align: middle !important;
        text-align: left;
      }
    }
  }
  .all-jobs-report-table {
    table {
      font-size: 11px;
      width: 100%;
      th:first-child {
        width: 220px;
      }
      th {
        background-color: $cs-grey-lightest;
        padding-bottom: 0px;
        padding-top: 5px;
        border-right: none;
        border-left: none;
        color: $cs-grey-darkest;
        // min-width: 60px!important;
        vertical-align: middle;
        text-align: left;
        height: 50px;
      }
      td {
        // min-width: 40px;
        vertical-align: middle !important;
        text-align: left;
      }
    }
  }
  .holiday-report-table {
    table {
      font-size: 11px;
      vertical-align: middle !important;
      text-align: left;
    }
    th {
      background-color: $cs-grey-lightest;
      padding-bottom: 0;
      padding-top: 5px;
      border-right: none;
      border-left: none;
      color: $cs-grey-darkest;
      height: 50px;
      vertical-align: middle;
      text-align: left;
    }
    th:nth-child(2) {
      width: 180px;
    }
    td {
      vertical-align: middle;
      text-align: left;
      min-width: 50px;
    }
  }
  .report-holiday-jobs-table {
    width: 98%;
    margin-left: 15px;
    table {
      font-size: 11px;
    }
    th {
      background-color: $cs-grey-lightest;
      padding-bottom: 0px;
      padding-top: 5px;
      border-right: none;
      border-left: none;
      color: $cs-grey-darkest;
      min-width: 60px !important;
      vertical-align: middle;
      text-align: left;
      height: 50px;
    }
    td {
      vertical-align: middle;
      text-align: left;
      min-width: 50px;
    }
  }
}

/* ======================================
=========================================
          &PROVIDER REQUEST QUOTA
=========================================
========================================*/

#tabs {
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  border-bottom: 5px solid #42a7c6;
}

#tabs li {
  margin: 0 2px;
}
#tabs.flex-list li {
  flex: 1;
}

#tabs li:first-child {
  margin-left: 0;
}

#tabs li:last-child {
  margin-right: 0;
}

#tabs a {
  font-family: "museo-sans", sans-serif;
  width: 100%;
  position: relative;
  background: #fff;
  padding: 0.7em 1.6em;
  float: left;
  text-decoration: none;
  color: #444;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

#tabs a:hover,
#tabs a:hover::after,
#tabs a:focus,
#tabs a:focus::after {
  background: #42a7c6;
  color: #fff;
}

#tabs #current a,
#tabs .nav-bar-active a {
  color: #fff;
  background: #42a7c6;
  font-weight: normal;
}

#content {
  background: #fff;
  padding: 2em;
  position: relative;
  z-index: 2;
  border-radius: 0 0 6px 6px;
}

.nav-bar-active {
  border-radius: 0 0 6px 6px;
}

.del-staff-level {
  float: right;
  font-size: 1.5em;
}
